import { flexpa__EndpointMode } from "@medbillai/graphql-types";

import type { Endpoint } from "../../lib/insurance-link/types";

export const INSURANCE_CARD_POLL_INTERVAL_MS = 2000;

export const FLEXPA_LINK_WAIT_TIME_MINUTES = 10;
export const WEB_AGENT_INGESTION_WAIT_TIME_MINUTES = 60;
export const WEB_AGENT_CRAWL_WAIT_TIME_MINUTES = 15;

export const resultUnavailableKey = "resultUnavailable";
export const resultUnavailablePlaceholder: Endpoint = {
  __typename: "flexpa__Endpoint_Type",
  displayName: resultUnavailableKey,
  id: resultUnavailableKey,
  slug: "PlaceHolder",
  isActive: true,
  organization: {
    id: "PlaceHolder",
    displayName: "PlaceHolder",
    slug: "PlaceHolder",
    isActive: true,
  },
  flexpaId: "PlaceHolder",
  mode: flexpa__EndpointMode.LIVE,
  name: "PlaceHolder",
  refreshable: true,
};
