import { Check as CheckIcon, X as XIcon } from "@tamagui/lucide-icons";

import {
  Ellipsis as EllipsisIcon,
  type ParagraphProps,
  type ThemeName,
  XStack,
  type XStackProps,
} from "@medbillai/ui";

interface statusCircleProps {
  status: string | null;
  wrapperProps?: XStackProps;
  textProps?: ParagraphProps;
}

const getCircleIcon = (status: string | null) => {
  switch (status) {
    case "approved":
      return <CheckIcon color="$color" size="$1" strokeWidth={1.75} />;
    case "denied":
      return <XIcon color="$color" size="$1" strokeWidth={1.75} />;
    case "pending":
      return <EllipsisIcon color="$color" size="$1" strokeWidth={2} />;
    default:
      return <EllipsisIcon color="$color" size="$1" strokeWidth={2} />;
  }
};

const getTheme = (status: string | null): ThemeName => {
  switch (status) {
    case "approved":
      return "green";
    case "denied":
      return "red";
    case "pending":
      return "lightBlue";
    default:
      return "lightBlue";
  }
};

export function StatusCircle({ status, wrapperProps }: statusCircleProps) {
  return (
    <XStack
      theme={getTheme(status)}
      alignItems="center"
      // No matching token
      br={12}
      jc="center"
      bg="$color3"
      width="$4"
      height="$4"
      borderWidth={0.5}
      borderColor="$color4"
      {...wrapperProps}
    >
      {getCircleIcon(status)}
    </XStack>
  );
}
