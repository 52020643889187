/* eslint-disable @typescript-eslint/no-unsafe-assignment */

/* eslint-disable react/prop-types */
import { memo } from "react";
import { Circle, Svg } from "react-native-svg";

import type { IconProps } from "@tamagui/helpers-icon";
import { themed } from "@tamagui/helpers-icon";

// The size and width are to compensate by that the path of this svg is
// inherently different, i would avoiding changing it to keep the same.
// @ts-expect-error To preserve the same API as lucide icons because of the
// theme(component) function
const Icon = props => {
  const { color = "black", size = 30, ...otherProps } = props;
  return (
    <Svg
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
      stroke={color}
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
      {...otherProps}
    >
      <Circle cx="12" cy="12" r=".5" fill={color} />
      <Circle cx="19" cy="12" r=".5" fill={color} />
      <Circle cx="5" cy="12" r=".5" fill={color} />
    </Svg>
  );
};

Icon.displayName = "Ellipsis";

export const Ellipsis = memo<IconProps>(themed(Icon));
